/* eslint arrow-body-style: 0 */
import { frontendURL } from '../../../helper/URLHelper';
const ConnectedView = () => import('./pages/ConnectedView.vue');

export const routes = [
  {
    path: frontendURL('accounts/:accountId/connected'),
    name: 'connected_overview',
    meta: {
      permissions: ['administrator', 'agent'],
    },
    component: ConnectedView,
  },
];
